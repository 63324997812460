import Home from "../views/Home";
import About from "../views/About";
import Services from "../views/Services";
import Blog from "../views/Blog";
import Contact from "../views/Contact";
import Projects from "../views/Projects";
import RealEstate from "../views/RealEstate";
import USGovernmentContract from "../views/USGovernmentContract";
import MediaStartup from "../views/MediaStartup";

const appRoutes = [
    {
        redirect: true,
        path: "/home",
        name: "Home",
        component: Home
    },
    {
        path: "/about",
        name: "About",
        component: About
    },
    {
        path: "/services",
        name: "Services",
        component: Services
    },
    {
        path: "/blog",
        name: "Blog",
        component: Blog
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact
    },
    {
        path: "/projects",
        name: "Project",
        component: Projects
    },
    {
        path: "/real_estate",
        name: "RealEstate",
        component: RealEstate
    },
    {
        path: "/us_government_contract",
        name: "USGovernmentContract",
        component: USGovernmentContract
    },
    {
        path: "/media_startup",
        name: "MediaStartup",
        component: MediaStartup
    },
    { redirect: true, path: "/", to: "/home", name: "Home" }
];

export default appRoutes;