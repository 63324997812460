import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import appRoutes from "./routes/routes.jsx";
import Home from "./views/Home";


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/" component={Home} />
      {appRoutes.map((prop, key) => {
        return <Route exact path={prop.path} component={prop.component} key={key} forceRefresh={true}/>;
      })}
    </Switch>
  </Router>,
  document.getElementById("root")
);