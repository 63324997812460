import React, { Component } from 'react';
import Header from "./Header";
import Footer from "./Footer";

export default class Services extends Component {
  render() {
    return (
      <div className="services">
        <Header location={this.props.location} />
        <WrapperConatainer />
        <Footer />
      </div>
    )
  }
}

class WrapperConatainer extends React.Component {
  render() {
    return (
      <div>
        {/* start: Page Title */}
        <div id="page-title">

          <div id="page-title-inner">

            {/* start: Container */}
            <div className="container">

              <h2><i className="ico-settings ico-white"></i>Services</h2>

            </div>
            {/* end: Container  */}

          </div>

        </div>
        {/* end: Page Title */}

        {/*start: Wrapper*/}
        <div id="wrapper">

          {/*start: Container */}
          <div className="container">

            {/* start: Row */}
            <div className="row">

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-imac ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Website Design &amp; Development</h3>
                  </div>
                  <p>
                    We use the latest and best practices in web design and development and offer beautiful front end designs coupled
                    with robust backend technologies. Our technologies in a snapshot: HTML 5, CSS 3, JavaScript &amp; jQuery, PHP,
                    Ruby, Rails, Python, MySQL, MongoDB, ASP.net.
        </p>
                  <div className="clear"></div>
                </div>
              </div>


              <div className="span4">
                <div className="icons-box">
                  <i className="ico-cloud ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Cloud</h3>
                  </div>
                  <p>
                    Cloud providers offer a lot of services but it takes cloud expertise to be able to make sense of the complex
                    capabilities offered. We can automate your deployment cycles, provision multiple environments for app developers
                    and provision complex services across multiple cloud providers.
        </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-iphone ico-color circle-color big"></i>
                  <div className="title">
                    <h3>iPhone / Android Apps</h3>
                  </div>
                  <p>
                    The sky is the limit when it comes to apps. We work with the most creative and experienced app developers around.
                    Show us a mobile application that you like and we can build exactly that for you. We'll help you strategize and
                    plan and allow your business to conquer mobile.
        </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-star ico-color circle-color big"></i>
                  <div className="title">
                    <h3>UI / UX Design</h3>
                  </div>
                  <p>
                    Yes we can make it work great but what really distinguishes us is how good we can make it look. We provide the
                    best UI experience whether it is your tablet, dekstop, browser or phone. Our UI designs are intuitive and sleek
                    and makes the user stick to our application.
        </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-resize-full ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Responsive</h3>
                  </div>
                  <p>
                    Our solutions are specially designed for optimum performance on a wide array of platforms. That means that our
                    users get the best experience whether they are on their PC, Mac, Tablet or Touchpads! We are dedicated to
                    providing the best quality.
        </p>
                  <div className="clear"></div>
                </div>
              </div>


              <div className="span4">
                <div className="icons-box">
                  <i className="ico-file-import ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Content Management System</h3>
                  </div>
                  <p>
                    Manage all of your website's content, plain and simple. Our custom built CMS provides you the tools and
                    capabilities to update your website. We also provide state of the arts CMS systems for industry specific needs
                    notable Health Care and Corporate Taxes.
        </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-shopping-cart ico-color circle-color big"></i>
                  <div className="title">
                    <h3>e-commerce</h3>
                  </div>
                  <p>
                    Launch an online store or enhance your current products online. Organize your items into departments, allow your
                    clients to chose different sizes and colors, and let your clients feel protected with our integrated secure
                    checkout and payment systems.
        </p>
                  <div className="clear"></div>
                </div>
              </div>


              <div className="span4">
                <div className="icons-box">
                  <i className="ico-database-plus ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Analytics &amp; Hosting</h3>
                  </div>
                  <p>
                    We provide cheap, reliable hosting on our cloud based network and provide the best user and data analytics on
                    your application. Enjoy 99.9% uptime on the same infrastructure that we host our own website and products on and
                    understand your users better.
        </p>
                  <div className="clear"></div>
                </div>
              </div>


              <div className="span4">
                <div className="icons-box">
                  <i className="ico-embed-close ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Mobile Websites &amp; Optimization</h3>
                  </div>
                  <p>
                    Want a website that looks in a browser like a website, but looks on a phone like an app. With our UIX design and
                    backend technology you can save on the costs of maintaing a website and an app by creating one product that
                    seamlessly transitions between all.
        </p>
                  <div className="clear"></div>
                </div>
              </div>


              <div className="span4">
                <div className="icons-box">
                  <i className="ico-embed-close ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Custom Development</h3>
                  </div>
                  <p>
                    If you think of it, Insigniac can build it. We have worked with countless industries (healthcare, real
                    estate, music blogs, media, to name a few) and provided solutions ranging from web technologies, Java and C++
                    developments, GUI frameworks and more.
        </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-search ico-color circle-color big"></i>
                  <div className="title">
                    <h3>SEM, SEO &amp; Pay-Per-Click</h3>
                  </div>
                  <p>
                    You can build a great product but if Google and other search engines doen't list your application on its first
                    page results, you won't gain traction. Our solutions search engine optimize your application enabling profit
                    ROI's by using data driven predictive modeling.
        </p>
                  <div className="clear"></div>
                </div>
              </div>

              <div className="span4">
                <div className="icons-box">
                  <i className="ico-thumbs-up ico-color circle-color big"></i>
                  <div className="title">
                    <h3>Social Media Integration</h3>
                  </div>
                  <p>
                    Share your content with the world. Insigniac provides you the tools to share your website's pages, news
                    stories, and blog posts to all of your social media outlets (Facebook and Twitter are just the tip of the
                    iceberg) with just one click.
        </p>
                  <div className="clear"></div>
                </div>
              </div>

            </div>
            {/* end: Row */}

          </div>
          {/*end: Container*/}

        </div>
        {/* end: Wrapper  
<link rel="stylesheet" type="text/css" href="css/fonts.css">*/}

      </div>
    )
  }
}