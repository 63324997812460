import React, { Component } from 'react';
import Header from "./Header";
import Footer from "./Footer";

export default class Blog extends Component {
  render() {
    return (
      <div className="blog">
         <Header location = {this.props.location}/>
        <WrapperContainer />
        <Footer />
      </div>
    )
  }
}

class WrapperContainer extends React.Component {
  render() {
    return (
      <div>
        <div id="wrapper">
          {/*start: Wrapper*/}
          {/*start: Container */}
          <div className="container">

            <hr />

            {/* start: Portfolio */}
            <div id="portfolio-wrapper" className="row">

              <div className="span4 portfolio-item">
                <div className="quotes">
                  <div className="quote">
                    “That’s not what we think design is. It’s not just what it looks like and feels like. Design is how it works”
        </div>
                  <div className="author">
                    - Steve Jobs -
        </div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="/projects" title="Title"><img src="assets/img/photo5.jpg" alt="" />
                  <div className="image-overlay-link"></div>
                </a>
                  <div className="item-description alt">
                    <h5><a href="/projects">Project no. 5</a></h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat.
          </p>
                  </div>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="quotes">
                  <div className="quote">
                    “In most people’s vocabularies, design means veneer. It’s interior decorating. It’s the fabric of the curtains of
                    the sofa. But to me, nothing could be further from the meaning of design. Design is the fundamental soul of a
                    human-made creation that ends up expressing itself in successive outer layers of the product or service.”
        </div>
                  <div className="author">
                    - Steve Jobs -
        </div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="/projects" title="Title"><img src="assets/img/photo1.jpg" alt="" />
                  <div className="image-overlay-link"></div>
                </a>
                  <div className="item-description alt">
                    <h5><a href="/projects">Project no. 1</a></h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
                      suscipit lobortis nisl ut aliquip ex ea commodo consequat.
          </p>
                  </div>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="assets/img/photo9.jpg" rel="image" title="Title"><img src="assets/img/photo9.jpg" alt="" />
                  <div className="image-overlay-zoom"></div>
                </a>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="assets/img/photo2.jpg" rel="image" title="Title"><img src="assets/img/photo2.jpg" alt="" />
                  <div className="image-overlay-zoom"></div>
                </a>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="/projects" title="Title">
                  <img src="assets/img/photo3.jpg" alt="" />
                  <div className="image-overlay-link"></div>
                </a>
                  <div className="item-description alt">
                    <h5><a href="/projects">Project no. 3</a></h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
                      suscipit lobortis nisl ut aliquip ex ea commodo consequat.
          </p>
                  </div>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="assets/img/photo4.jpg" rel="image" title="Title"><img src="assets/img/photo4.jpg" alt="" />
                  <div className="image-overlay-zoom"></div>
                </a>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="flex-video">
                  <iframe src="https://player.vimeo.com/video/3538565?portrait=0&color=ffffff" width="480" height="256" frameBorder="0"
                    webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen></iframe>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="/projects" title="Title"><img src="assets/img/photo6.jpg" alt="" />
                  <div className="image-overlay-link"></div>
                </a>
                  <div className="item-description alt">
                    <h5><a href="/projects">Project no. 6</a></h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
                      suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in
                      vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et
                      iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
          </p>
                  </div>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="assets/img/photo7.jpg" rel="image" title="Title"><img src="assets/img/photo7.jpg" alt="" />
                  <div className="image-overlay-zoom"></div>
                </a>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="/projects" title="Title"><img src="assets/img/photo8.jpg" alt="" />
                  <div className="image-overlay-link"></div>
                </a>
                  <div className="item-description alt">
                    <h5><a href="/projects">Project no. 8</a></h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
                      suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in
                      vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et
                      iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
          </p>
                  </div>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item scenery">
                <div className="quotes">
                  <div className="quote">
                    “Innovation distinguishes between a leader and a follower.”
        </div>
                  <div className="author">
                    - Steve Jobs -
        </div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="quotes">
                  <div className="quote">
                    “Visual design is often the polar opposite of engineering: trading hard edges for subjective decisions based on
                    gut feelings and personal experiences. It’s messy, unpredictable, and notoriously hard to measure. The apparently
                    erratic behavior of artists drives engineers bananas. Their decisions seem arbitrary and risk everything with no
                    guaranteed benefit.”
        </div>
                  <div className="author">
                    - Scott Stevenson -
        </div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="/projects" title="Title"><img src="assets/img/photo10.jpg" alt="" />
                  <div className="image-overlay-link"></div>
                </a>
                  <div className="item-description alt">
                    <h5><a href="/projects">Project no. 10</a></h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
                      suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in
                      vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et
                      iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
          </p>
                  </div>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="/projects" title="Title"><img src="assets/img/photo11.jpg" alt="" />
                  <div className="image-overlay-link"></div>
                </a>
                  <div className="item-description alt">
                    <h5><a href="/projects">Project no. 11</a></h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                      dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper
                      suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in
                      vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et
                      iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
          </p>
                  </div>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

              <div className="span4 portfolio-item">
                <div className="picture"><a href="assets/img/photo12.jpg" rel="image" title="Title"><img src="assets/img/photo12.jpg" alt="" />
                  <div className="image-overlay-zoom"></div>
                </a>
                  <div className="post-meta"><span><i className="mini-ico-calendar"></i>1 June 2011</span> <span><i className="mini-ico-user"></i>
                    <a href="#">lucas</a></span> <span><i className="mini-ico-comment"></i><a href="#">89 comments</a></span></div>
                </div>
              </div>

            </div>
            {/* end: Portfolio */}

          </div>
          {/*end: Container*/}
          {/* end: Wrapper  */}
        </div>
      </div>
    )
  }
}