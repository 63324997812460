import React, { Component } from 'react';
import Header from "./Header";
import Footer from "./Footer";

export default class MediaStartup extends Component {
  render() {
    return (
      <div className="mediastartup">
         <Header location = {this.props.location}/>
        <WrapperContainer />
        <Footer />
      </div>
    )
  }
}

class WrapperContainer extends React.Component {
  render() {
    return (
      <div>
        {/* start: Page Title */}
        <div id="page-title">

          <div id="page-title-inner">

            {/* start: Container */}
            <div className="container">

              <h2><i className="ico-keynote ico-white"></i>Media Startup</h2>

            </div>
            {/* end: Container  */}

          </div>

        </div>
        {/* end: Page Title */}

        {/*start: Wrapper */}
        <div id="wrapper">

          {/*start: Container */}
          <div className="container">

            {/* start: Flexslider */}
            <div className="slider">
              <div id="flex1" className="flexslider home">
                <ul className="slides">

                  <li>
                    <img src="/assets/img/slider/slider1.jpg" alt="" />
                    <div className="slide-caption n">
                      <h3>Media Startup</h3>
                    </div>
                  </li>

                  <li>
                    <img src="/assets/img/slider/slider2.jpg" alt="" />
                    <div className="slide-caption">
                      <h3>Media Startup</h3>
                    </div>
                  </li>

                  <li>
                    <img src="/assets/img/slider/slider3.jpg" alt="" />
                    <div className="slide-caption">
                      <h3>Media Startup</h3>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
            {/* end: Flexslider */}

            <hr />

            {/* start: Row */}
            <div className="row">

              <div className="span2">

                <ul className="project-info">
                  <li><strong>Client:</strong> Google</li>
                  <li><strong>Date:</strong> August 2019</li>
                </ul>
                

              </div>

              <div className="span10">
                <p>Technologies: PHP, MySQL, jQuery, AJAX</p>
                <p>
                  Designed and developed ClipVote, a media startup powering video competitions online. Combined PHP and Python based backend with AJAX and jQuery on the front end to create a highly dynamic application.
				</p>
              </div>

            </div>
            {/* end: Row */}
            <hr />
            {/* start: Row */}
          </div>
        </div>
      </div>
    )
  }
}