import React, { Component } from 'react';
import Header from "./Header";
import Footer from "./Footer";

export default class About extends Component {
  render() {
    return (
      <div className="about">
        <Header location={this.props.location} />
        <WrapperContainer />
        <Footer />
      </div>
    )
  }
}

class WrapperContainer extends React.Component {
  render() {
    return (
      <div>
        {/* start: Page Title */}
        <div id="page-title">
          <div id="page-title-inner">
            {/* start: Container */}
            <div className="container">
              <h2><i className="ico-stats ico-white"></i>About Us</h2>
            </div>
            {/* end: Container  */}
          </div>
        </div>
        {/* end: Page Title */}
        {/*start: Wrapper*/}
        <div id="wrapper">
          {/*start: Container */}
          <div className="container">
            {/*start: Row */}
            <div className="row">
              <div className="span8">
                {/* start: About Us */}
                <div id="about">
                  <div className="title">
                    <h3>About Us</h3>
                    </div>
                  <p>
                    We are a results driven software development firm
  driving key software technology and enterprise
  application integration (EAI) projects in the U.S. and
  international markets.
                    </p>
                  <p>
                    Insigniac has a talented pool of high-technology
  experts. We possess a wide span of well-balanced
  knowledge, which includes complex architectures and
  platforms. We cover the full systems development life
  cycle, from inception to final implementation and
  vendor management experience.
                    </p>
                  <p>
                    The corporation is adept at working in fragmented
  business environments and proficient at rescuing
  faltering projects to meet corporate timelines and
  objectives. Insignia possesses broad strategic skills
  that extend beyond traditional IT operational
  boundaries including finance, contracting, project
  planning, and requirements definition.
                    </p>
                  <p>
                    Functional competencies of the company include
  software and systems architecture and design,
  business case development, software testing,
  enterprise application integration, cross-functional
  team integration, project management, web systems
  development, and client and customer management.
                    </p>
                </div>
                {/* end: About Us */}
                {/* start: History */}
                <div id="history">
                  <div className="title">
                    <h3>Technology</h3>
                  </div>
                  <p>
                    We also work with innovative technology startups and
  are uniquely poised to provide technical, hosting and
  business management consulting to upcoming
  startups. We encourage US based startups to
  approach us with their business model and we
  provide the developer and management resources
  necessary to take the startup idea from conception to
  a fully functioning software platform
                    </p>
                  <p>
                    Typically, startups with limited resources come to us
  with a design of a product (web or any other kind of
  software) that they have tracked some interest in. Our
  strategy team here at Insigniac reviews the
  design and the product idea and then offer to take
  some limited equity in the product in exchange for
  cheaper development rates and access to a large
  network of developers, designers, entrepreneurs, and
  angel investors. We are constantly looking to expand
  our portfolio so hit us up and get a quote.
                    </p>
                </div>
                {/* end: History */}
                {/* start: Team */}
                <div id="team">
                  <div className="title">
                    <h3>Team</h3>
                  </div>
                  {/* start: Row */}
                  <div className="row">
                    {/* start: About Member */}
                    <div className="span4">
                      <img src="https://ngtrends.com/wp-content/uploads/2012/07/Facebook-2.jpg" alt="team member" />
                      <div className="team-name">Ken Krueger <span> CEO</span></div>
                      <p>
                        Ken has had a long and diverse career in IT spanning over 25 years. In this time, Ken has worked as an expert
                        developer, project manager and product innovator. As part of Insigniac, Ken is responsible for
                        allocating resources and opening up new markets for the firm. Ken has been crucial in establishing out strong
                        client base across the Atlantic seaboard and the American midwest and is currently involved in securing long
                        term contracts in the South East Asian markets.
                            </p>
                      <div className="social_profiles">
                        <ul className="social-bookmarks">
                          <li className="linkedin"><a href="https://www.linkedin.com" rel='noopener noreferrer' target="_blank">linkedin</a></li>
                        </ul>
                      </div>
                    </div>
                    {/* end: About Member */}
                    {/* start: About Member */}
                    <div className="span4">
                      <img src="https://ngtrends.com/wp-content/uploads/2012/07/Facebook-2.jpg" alt="team member" />
                      <div className="team-name">Bobby Singh <span> Leadership Support</span></div>
                      <p>
                        Before joining Insigniac, Bobby acted as a highly successful Project Manager, Project Lead, Team Lead,
                        Architect, Analyst and Developer at different times. Managed teams up to 85 people based in the United States,
                        France and the United Kingdom. In this capacity, Bobby interacted with the in-house and client management at
                        the highest level over a sustained period of time through presentations, face-to-face meetings, constant
                        reviews, and informal conferencing.
                            </p>
                      <div className="social_profiles">
                        <ul className="social-bookmarks">
                          <li className="linkedin"><a href="https://www.linkedin.com/in/bob-bapna-0538661" rel='noopener noreferrer' target="_blank">linkedin</a></li>
                        </ul>
                      </div>
                    </div>
                    {/* end: About Member */}
                  </div>
                  {/* end: Row */}
                </div>
                {/* end: Team */}
              </div>
              <div className="span4">
                {/* start: Sidebar */}
                <div id="sidebar">
                  {/* start: Skills */}
                  <div className="title">
                    <h3>Our Skills</h3>
                  </div>
                  <ul className="progress-bar">
                    <li>
                      <h5>Web Design and Development ( 100% )</h5>
                      <div className="meter"><span style={{ width: '100%' }}></span></div>{/* Edite width here */}
                    </li>
                    <li>
                      <h5>Iphone / Android Apps ( 90% )</h5>
                      <div className="meter"><span style={{ width: '90%' }}></span></div>{/* Edite width here */}
                    </li>
                    <li>
                      <h5>UI / UX Design ( 90% )</h5>
                      <div className="meter"><span style={{ width: '90%' }}></span></div>{/* Edite width here */}
                    </li>
                    <li>
                      <h5>Content Management Systems ( 100% )</h5>
                      <div className="meter"><span style={{ width: '100%' }}></span></div>{/* Edite width here */}
                    </li>
                    <li>
                      <h5>SEM, SEO and Pay-Per-Click ( 70% )</h5>
                      <div className="meter"><span style={{ width: '70%' }}></span></div>{/* Edite width here */}
                    </li>
                    <li>
                      <h5>E-Commerce Platforms ( 90% )</h5>
                      <div className="meter"><span style={{ width: '90%' }}></span></div>{/* Edite width here */}
                    </li>
                    <li>
                      <h5>Analytics and Hosting ( 100% )</h5>
                      <div className="meter"><span style={{ width: '100%' }}></span></div>{/* Edite width here */}
                    </li>
                    <li>
                      <h5>Social Media ( 100% )</h5>
                      <div className="meter"><span style={{ width: '100%' }}></span></div>{/* Edite width here */}
                    </li>
                  </ul>
                  {/* end: Skills */}
                  {/* start: Testimonials*/}
                  <div className="testimonial-container">
                    <div className="title">
                      <h3>What Client’s Say</h3>
                    </div>
                    <div className="testimonials-carousel" data-autorotate="3000">
                      <ul className="carousel">
                        <li className="testimonial">
                          <div className="testimonials">Dulles Medical Center is an enterprise focusing on delivering
                              healthcare services to the private sector. We had outsourced the development of our
                              integrated Medical Services / Medical Records system to a third party vendor and
                              were two years behind schedule and two hundred thousand dollars over
                              budget.Insigniac assisted in quickly mapping out the existing bottlenecks and
                                        completely overhauled our systems.</div>
                          <div className="testimonials-bg"></div>
                          <div className="testimonials-author">Victor Railan, <span>CEO</span></div>
                        </li>
                        <li className="testimonial">
                          <div className="testimonials">Our Real Estate firm needed a web based system that was
                              capable of handling high volumes of traffic.Insigniac worked with our stakeholders
                              and developed a robust user friendly application that has seen tremendous growth
                              since its launch. Because of our success in working with Insigniac, we have
                              contracted the firm as our permanent support staff for our existing and future IT
                                        systems for the next 2 years. </div>
                          <div className="testimonials-bg"></div>
                          <div className="testimonials-author">MasterServ Financial</div>
                        </li>
                        <li className="testimonial">
                          <div className="testimonials">We are Software shop based in the Los Angeles area. We work
                              with upcoming startups in the Los Angeles area to grow and maintain their code
                              base. We worked withInsigniac to implement coding guidelines, infrastructure
                              architecture, database designs and cloud hosting solutions that allows our clients
                              to seamlessly handle anywhere between 5000 to 5 million users with 99% Service
                                        uptime.</div>
                          <div className="testimonials-bg"></div>
                          <div className="testimonials-author">thatsgoodsoftware, <span>CTO</span></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* end: Testimonials*/}
                  {/* end: Testimonials*/}
                </div>
                {/* end: Sidebar */}
              </div>
            </div>
            {/*end: Row*/}
          </div>
          {/*end: Container*/}
          {/*start: Container */}
          <div className="container">
            <hr />
            <div className="row">
              <div className="title">
                <h3>Our Clients</h3>
              </div>
              <div className="span3"></div>
              <div className="span3"><a href="https://healthy.kaiserpermanente.org" rel='noopener noreferrer' target="_blank"><img src="/assets/img/rajinternational/home/clients/kaiser.png"
                alt="Kaiser Permanente" /></a></div>
              <div className="span3"><a href="https://www.crunchbase.com/organization/copromote" rel='noopener noreferrer' target="_blank"><img src="/assets/img/rajinternational/home/clients/copromote.png"
                alt="CoPromote | Crunchbase" /></a></div>
            </div>
            <hr />
          </div>
          {/*end: Container*/}
        </div>
        {/* end: Wrapper  */}
      </div>
    );
  }
}