import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <div>
        {/* start: Footer Menu */}
        <div id="footer-menu" className="hidden-tablet hidden-phone">
          {/* start: Container */}
          <div className="container">
            {/* start: Row */}
            <div className="row">
              {/* start: Footer Menu Logo */}
              <div className="span4">
                <div id="footer-menu-logo">
                  <div id="logo-chart"></div><a className="brand" href="/">Insig<span>niac</span></a>
                </div>
              </div>
              {/* end: Footer Menu Logo */}
              {/* start: Footer Menu Links*/}
              <div className="span7">
                <div id="footer-menu-links">
                  <ul id="footer-nav">
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/contact">Contact</a></li>
                  </ul>
                </div>
              </div>
              {/* end: Footer Menu Links*/}
              {/* start: Footer Menu Back To Top */}
              <div className="span1">
                <div id="footer-menu-back-to-top">
                  <a href="#top"></a>
                </div>
              </div>
              {/* end: Footer Menu Back To Top */}
            </div>
            {/* end: Row */}
          </div>
          {/* end: Container  */}
        </div>
        {/* end: Footer Menu */}

        {/* start: Footer */}
        <div id="footer">
          {/* start: Container */}
          <div className="container">
            {/* start: Row */}
            <div className="row">
              {/* start: About */}
              <div className="span3">
                <h3>About Us</h3>
                <p>
                  Insigniac is a successful Software and Applications
Consultancy. We specialize in building and operating
infrastructure on the AWS cloud. Our client’s focus on
building their applications, we structure and manage
all complex aspects of the underlying infrastructure
and how to run our clients applications. </p>
              </div>
              {/* end: About */}

              {/* end: Photo Stream */}
              <div className="span4">
                {/* start: Follow Us */}
                <h3>Follow Us!</h3>
                <ul className="social-grid">
                  <li>
                    <div className="social-item">
                      <div className="social-info-wrap">
                        <div className="social-info">
                          <div className="social-info-front social-twitter">
                            <a href="https://twitter.com/Insigniac11" title="Twitter" rel="noopener noreferrer" target="_blank"></a>
                          </div>
                          <div className="social-info-back social-twitter-hover">
                            <a href="https://twitter.com/Insigniac11" title="Twitter" rel="noopener noreferrer" target="_blank"></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="social-item">
                      <div className="social-info-wrap">
                        <div className="social-info">
                          <div className="social-info-front social-linkedin">
                            <a href="https://www.linkedin.com/company/insigniac/" title="LinkedIn"
                              rel="noopener noreferrer" target="_blank"></a>
                          </div>
                          <div className="social-info-back social-linkedin-hover">
                            <a href="https://www.linkedin.com/company/insigniac/" title="LinkedIn"
                              rel="noopener noreferrer" target="_blank"></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="span5">
                {/* end: Follow Us */}
                {/* start: Newsletter */}
                <form id="newsletter">
                  <h3>Newsletter</h3>
                  <p>Please leave us your email</p>
                  <label htmlFor="newsletter_input">@:</label>
                  <input type="text" id="newsletter_input" />
                  <input type="submit" id="newsletter_submit" value="submit" />
                </form>
                {/* end: Newsletter */}
              </div>
            </div>
            {/* end: Row */}
          </div>
          {/* end: Container  */}
        </div>
        {/* end: Footer */}

        {/* start: Copyright */}
        <div id="copyright">
          {/* start: Container */}
          <div className="container">
            <div className="span12">
              <p>
                &copy; <a href="/">Insigniac</a><img src="/assets/img/poland2.png" alt="Poland" style={{ marginRight: -4 + 'em' }} />
              </p>
            </div>
          </div>
          {/* end: Container  */}
        </div>
        {/* end: Copyright */}
      </div>
    )
  }
}
