import React, { Component } from 'react';
import Header from "./Header";
import Footer from "./Footer";

export default class Projects extends Component {
  render() {
    return (
      <div className="projects">
        <Header location={this.props.location} />
        <WrapperContainer />
        <Footer />
      </div>
    )
  }
}


class WrapperContainer extends React.Component {
  render() {
    return (
      <div>
        {/* start: Page Title */}
        <div id="page-title">

          <div id="page-title-inner">

            {/* start: Container */}
            <div className="container">

              <h2><i className="ico-keynote ico-white"></i>Project Name</h2>

            </div>
            {/* end: Container  */}

          </div>

        </div>
        {/* end: Page Title */}

        {/*start: Wrapper */}
        <div id="wrapper">

          {/*start: Container */}
          <div className="container">

            {/* start: Flexslider */}
            <div className="slider">
              <div id="flex1" className="flexslider home">
                <ul className="slides">

                  <li>
                    <img src="/assets/img/slider/slider1.jpg" alt="" />
                    <div className="slide-caption n">
                      <h3>This is a caption</h3>
                    </div>
                  </li>

                  <li>
                    <img src="/assets/img/slider/slider2.jpg" alt="" />
                    <div className="slide-caption">
                      <h3>This is a caption</h3>
                    </div>
                  </li>

                  <li>
                    <img src="/assets/img/slider/slider3.jpg" alt="" />
                  </li>

                </ul>
              </div>
            </div>
            {/* end: Flexslider */}

            <hr />

            {/* start: Row */}
            <div className="row">

              <div className="span2">

                <ul className="project-info">
                  <li><strong>Client:</strong> Google</li>
                  <li><strong>Date:</strong> August 2012</li>
                </ul>
               

              </div>

              <div className="span10">

                <p>
                  <span className="dropcap">A</span>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                  euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci
                  tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in
                  hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et
                  accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla
                  facilisi.
				</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                  dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
                  lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit
                  esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio
                  dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
				</p>
                <blockquote>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet
                  dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
                  lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit
                  esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio
                  dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.
				</blockquote>
                <p>
                  <span className="dropcap color">B</span>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
                  nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
                  exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure
                  dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero
                  eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait
                  nulla facilisi.
				</p>

              </div>

            </div>
            {/* end: Row */}
            <hr />
            {/* start: Row */}
          </div>
        </div>
      </div>
    )
  }
}