import React, { Component } from 'react';
import Header from "./Header";
import Footer from "./Footer";

export default class Home extends Component {

  render() {
    return (
      <div className="home">
        <Header location={this.props.location} />
        <CarouselSlider />
        <WrapperContiner />
        <Footer />
      </div>
    )
  }
}

class CarouselSlider extends React.Component {
  render() {
    return (
      <div>
        {/* start: Slider */}
        <div className="slider-wrapper">

          <div id="da-slider" className="da-slider">
            <div className="da-slide">
              <h2>We build software</h2>
              <p>Content Management Systems, Websites, Mobile Applications, E-Commerce platforms and more. If you
  think of it,Insigniac can build it.</p>
              <a href="/projects" className="da-link">Read more</a>
              <div className="da-img"><img src="/assets/img/parallax-slider/twitter.png" alt="Twitter" /></div>
            </div>
            <div className="da-slide">
              <h2>App Development</h2>
              <p>We are masters of everything web. We provide full scale web, mobile and tablet solutions tailored to
  your needs.</p>
              <a href="/projects" className="da-link">Read more</a>
              <div className="da-img"><img src="/assets/img/parallax-slider/responsive.png" alt="Read More" /></div>
            </div>
            <div className="da-slide">
              <h2>Cheapest Rates Guaranteed</h2>
              <p>You get the highest quality work at amazingly low cost. Don't believe us? Reach out to us and we
  promise you it would be worth your while.</p>
              <a href="/projects" className="da-link">Read more</a>
              <div className="da-img"><img src="/assets/img/parallax-slider/html5.png" alt="Read More" /></div>
            </div>
            <div className="da-slide">
              <h2>Cloud Solutions</h2>
              <p>We can automate your cloud infrastructure and deployments.</p>
              <a href="/projects" className="da-link">Read more</a>
              <div className="da-img"><img src="/assets/img/parallax-slider/css3.png" alt="Read More" /></div>
            </div>
            <nav className="da-arrows">
              <span className="da-arrows-prev"></span>
              <span className="da-arrows-next"></span>
            </nav>
          </div>

        </div>
        {/* end: Slider */}
      </div>
    );
  }
}


class WrapperContiner extends React.Component {
  render() {
    return (
      <div>
        {/*start: Wrapper*/}
        <div id="wrapper">

          {/*start: Container */}
          <div className="container">

            <hr />

            {/* start: Hero Unit - Main hero unit for a primary marketing message or call to action */}
            <div className="hero-unit">
              <h3>
                Insigniac is a boutique, results driven software development company with over twenty-five years of
                experience in leading key software technology and enterprise application integration (EAI) projects in
                the US domestic and international markets.
        </h3>
              <p><a href="/projects" className="btn btn-primary btn-large">Learn more &raquo;</a></p>
            </div>
            {/* end: Hero Unit */}

            <hr />

            {/* start: Row */}
            <div className="row">

              {/* start: Icon Boxes */}
              <div className="icons-box-vert-container">

                {/* start: Icon Box Start */}
                <div className="span4">
                  <div className="icons-box-vert">
                    <i className="ico-ok ico-white circle-color-full"></i>
                    <div className="icons-box-vert-info">
                      <h3>Strategizing, Planning and Designing</h3>
                      <p>Tell us how you envision your perfect product…we’ll
guarantee it happens. Not sure about some aspects
of your product or service? We have experts on staff
ready to provide valuable advice.</p>
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
                {/* end: Icon Box*/}

                {/* start: Icon Box Start */}
                <div className="span4">
                  <div className="icons-box-vert">
                    <i className="ico-cup  ico-white circle-color-full"></i>
                    <div className="icons-box-vert-info">
                      <h3>Execution, Testing, and Certification</h3>
                      <p>Work with us, you’ll get our total undivided attention.
We provide product managers and developers solely
dedicated to building and optimizing your product.</p>
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
                {/* end: Icon Box */}

                {/* start: Icon Box Start */}
                <div className="span4">
                  <div className="icons-box-vert">
                    <i className="ico-ipad ico-white circle-color-full"></i>
                    <div className="icons-box-vert-info">
                      <h3>Launching, Management, and Growth</h3>
                      <p>Launch is the most exciting part of entire process,
which delivers and transforms your online presence.
We’ll support you 24/7 throughout the launch and
establishment process.</p>
                    </div>
                    <div className="clear"></div>
                  </div>
                </div>
                {/* end: Icon Box */}

              </div>
              {/* end: Icon Boxes */}
              <div className="clear"></div>
            </div>
            {/* end: Row */}

            <hr />

            {/* start: Row */}
            <div className="row">
              <div className="span9">
                <div className="title">
                  <h3>Latest Works</h3>
                </div>
                {/* start: Row */}
                <div className="row">
                  <div className="span3">
                    <div className="picture">
                      <a href="/assets/img/car.jpg" rel="image" title="Real Estate">
                        <img src="/assets/img/car.jpg" alt="Real Estate" />
                        <div className="image-overlay-zoom"></div>
                      </a>
                    </div>
                    <div className="item-description">
                      <h4><a href="/real_estate">Real Estate</a></h4>
                      <h4>Technologies: C#, C++, .NET, SOAP</h4>
                      <p>Developed a .NET based solution for MasterServ Financial, a Washington DC based Real
                          estate firm. Our solution were designed for high volumes and scalability with about
                            5000 users using the application daily.</p>
                    </div>
                  </div>

                  <div className="span3">
                    <div className="picture">
                      <a href="/media_startup">
                        <img src="/assets/img/web_app1.jpg" alt="Media Startup" />
                        <div className="image-overlay-link"></div>
                      </a>
                    </div>
                    <div className="item-description">
                      <h4><a href="/media_startup" title="Media Startup">Media Startup</a></h4>
                      <h4>Technologies: PHP, MySQL, jQuery, AJAX</h4>
                      <p>Designed and developed ClipVote, a media startup powering video competitions online.
                          Combined PHP and Python based backend with AJAX and jQuery on the front end to create a
                            highly dynamic application.</p>
                    </div>
                  </div>

                  <div className="span3">
                    <div className="picture">
                      <a href="/assets/img/mobile_app1.jpg" rel="image" title="US Government Contract">
                        <img src="/assets/img/mobile_app1.jpg" alt="US Government Contract" />
                        <div className="image-overlay-zoom"></div>
                      </a>
                    </div>
                    <div className="item-description">
                      <h4><a href="/us_government_contract">US Government Contract</a></h4>
                      <h4>Technologies: Migration, METIS, FEAF</h4>
                      <p>Developed an Enterprise Management System for the Federal Energy Regulatory Commission
                          (FERC). We streamlined the IT management systems and saved FERC millions of dollars in
                            management costs.</p>
                    </div>
                  </div>

                </div>
                {/* end: Row */}

              </div>

              <div className="span3">
                {/* start: Testimonials*/}
                <div className="testimonial-container">
                  <div className="title">
                    <h3>What Client’s Say</h3>
                  </div>
                  <div className="testimonials-carousel" data-autorotate="3000">
                    <ul className="carousel">
                      <li className="testimonial">
                        <div className="testimonials">Dulles Medical Center is an enterprise focusing on delivering
                            healthcare services to the private sector. We had outsourced the development of our
                            integrated Medical Services / Medical Records system to a third party vendor and
                            were two years behind schedule and two hundred thousand dollars over
                            budget.Insigniac assisted in quickly mapping out the existing bottlenecks and
                                completely overhauled our systems.</div>
                        <div className="testimonials-bg"></div>
                        <div className="testimonials-author">Victor Railan, <span>CEO</span></div>
                      </li>

                      <li className="testimonial">
                        <div className="testimonials">Our Real Estate firm needed a web based system that was
                            capable of handling high volumes of traffic.Insigniac worked with our stakeholders
                            and developed a robust user friendly application that has seen tremendous growth
                            since its launch. Because of our success in working with Insigniac, we have
                            contracted the firm as our permanent support staff for our existing and future IT
                                systems for the next 2 years. </div>
                        <div className="testimonials-bg"></div>
                        <div className="testimonials-author">MasterServ Financial</div>
                      </li>


                      <li className="testimonial">
                        <div className="testimonials">We are Software shop based in the Los Angeles area. We work
                            with upcoming startups in the Los Angeles area to grow and maintain their code
                            base. We worked withInsigniac to implement coding guidelines, infrastructure
                            architecture, database designs and cloud hosting solutions that allows our clients
                            to seamlessly handle anywhere between 5000 to 5 million users with 99% Service
                                uptime.</div>
                        <div className="testimonials-bg"></div>
                        <div className="testimonials-author">thatsgoodsoftware, <span>CTO</span></div>
                      </li>

                    </ul>
                  </div>
                </div>
                {/* end: Testimonials*/}
              </div>
            </div>
            {/* end: Row */}

            <hr />
            <div className="row">
              <div className="title">
                <h3>Our Clients</h3>
              </div>
              <div className="span3"></div>
              <div className="span3"><a href="https://healthy.kaiserpermanente.org/" rel="noopener noreferrer" target="_blank"><img src="/assets/img/rajinternational/home/clients/kaiser.png"
                alt="Kaiser Permanente" /></a></div>
              <div className="span3"><a href="https://www.crunchbase.com/organization/copromote" rel="noopener noreferrer" target="_blank"><img src="/assets/img/rajinternational/home/clients/copromote.png"
                alt="CoPromote | Crunchbase" /></a></div>
            </div>
          </div>
          {/* end Clients List */}
          <hr />
        </div>
        {/*end: Container*/}
      </div>
    );
  }
}